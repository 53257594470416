<template>
    <div style="min-height:800px; height:100vh !important" class="container-fluid d-flex flex-column">
        <div class="row-fluid" style="height:50px"></div>
        <div style="margin-top:40px" class="row-fluid p-3">
            <h1 class="p-sm-5">ERRORE 404</h1>
        </div>
        <div class="row bg-white align-self-stretch flex-grow-1 d-flex flex-column align-items-center">
            <div class="h-100 d-flex align-items-center p-5 text-center">
                <div class="mt-2 text-center">
                    <h1 class="m-0" style="font-size:120px; color:black; line-height:1;">404</h1>
                    <i class="text-danger mt-1 mb-3 mr-2 fas fa-exclamation-triangle fa-4x"></i>
                    <h1 class="text-danger mb-3" style="color:black; font-weight:bold;">ATTENZIONE</h1>
                    <b><p style="color:black">LA PAGINA CHE STAI CERCANDO DI VISITARE NON ESISTE</p></b>
                    <p>Clicca <router-link to="/">qui</router-link> per tornare alla <b>HOME</b></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    metaInfo:{
        title: 'Hackintosh Italia',
        titleTemplate:'%s - 404',
        meta:[
            {charset: 'utf-8'},
        ]
    },
}
</script>

<style>
</style>